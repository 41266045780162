export default function Footer() {
  return (
    <footer className="bg-slate-50 py-5 pb-7">
      <div className="mx-auto px-6 md:flex md:items-center md:justify-between">
        <div className="flex justify-center space-x-6 md:order-2">
          
        </div>
        <div className="mt-8 md:order-1 md:mt-0">
          <p className="text-center text-xs leading-5 text-gray-500">
            &copy; 2020 Bank App, Inc. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}
