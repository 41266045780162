import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

export default function Header() {
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          style={{
            backgroundImage:
              "url('https://images.unsplash.com/photo-1475274047050-1d0c0975c63e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bmlnaHQlMjBza3l8ZW58MHx8MHx8&w=1000&q=80')",
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: 100,
            justifyContent: 'center'
          }}
          position="static"
        >
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            ></IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
            ></Typography>
            <img
              style={{ marginRight: '30px' }}
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/71/Earth_icon_2.png/640px-Earth_icon_2.png"
              alt="Girl in a jacket"
              width={50}
              height={50}
            />
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}
