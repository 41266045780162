import React from 'react';

import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import {
  BanknotesIcon,
  CreditCardIcon,
  CurrencyEuroIcon,
  ShieldCheckIcon,
  UserCircleIcon
} from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const navigation = [
  { name: 'Cash Accounts', href: '#', icon: BanknotesIcon, current: true },
  { name: 'Credit Cards', href: '#', icon: CreditCardIcon, current: false },
  { name: 'Securities', href: '#', icon: ShieldCheckIcon, current: false },
  { name: 'Payments', href: '#', icon: CurrencyEuroIcon, current: false },
  { name: 'Personal Data', href: '#', icon: UserCircleIcon, current: false }
];

export default function SideBar() {
  return (
    <div
      className="hidden shadow-lg lg:inset-y-1 lg:z-50 lg:flex lg:w-72 lg:flex-col ml-12 rounded-lg overflow-hidden"
    >
      <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-5 pt-5 ">
        <nav className="flex flex-1 flex-col">
          <ul role="list" className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" className="-mx-2 space-y-1">
                {navigation.map((item) => (
                  <li key={item.name}>
                    <a
                      href={item.href}
                      className={classNames(
                        item.current
                          ? 'bg-gray-50 text-indigo-600'
                          : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.current
                            ? 'text-indigo-600'
                            : 'text-gray-400 group-hover:text-indigo-600',
                          'h-6 w-6 shrink-0'
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
