import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import React from 'react';
import Layout from './Components/Layout/Layout';

const App = () => {
  return (
    <Layout/>
  );
};

export default App;
