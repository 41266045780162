import React, { useState, useHistory, useEffect } from 'react';
import {
  ArrowLeftCircleIcon,
  ChevronDownIcon
} from '@heroicons/react/20/solid';
import { Switch } from '@headlessui/react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Button,
  TextField,
  FormControl,
  Box,
  IconButton,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  Typography
  //   Radio
} from '@mui/material';
import { ArrowBackRounded } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import axios from 'axios';
import { format, compareAsc } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Register() {
  const [serverResponse, setServerResponse] = useState({
    e2eProcessId: '',
    precontractualInformation: '',
    productApplicationId: '',
    productApplicationStatus: ''
  });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [dateOfBirth, setDateOfBirth] = useState(new Date());

  const [formInput, setFormInput] = useState({
    firstName: '',
    lastName: '',
    sex: 'FEMALE',
    placeOfBirth: '',
    emailAddress: '',
    internationalAreaCode: '+49',
    areaCode: '',
    telephoneNumber: '',
    nationality: 'german',
    maritalStatus: 'UNKNOWN',
    // maidenName: '',
    // academicTitle: 'DR',
    proprietaryStatus: 'RENTING_OR_SUBTENANT',
    employmentStatus: 'EMPLOYED',
    houseNumberR: '',
    streetR: '',
    zipCodeR: '',
    cityR: '',
    countryR: 'germany',
    houseNumberC: '',
    streetC: '',
    zipCodeC: '',
    cityC: '',
    countryC: 'germany',
    employmentLevel: 'SENIOR_EMPLOYEE',
    selfEmployedTaxID: ''
  });

  const [formInputError, setFormInputError] = useState({
    firstName: false,
    lastName: false,
    sex: 'FEMALE',
    placeOfBirth: false,
    emailAddress: false,
    internationalAreaCode: '+49',
    areaCode: '',
    telephoneNumber: '',
    nationality: 'german',
    maritalStatus: 'UNKNOWN',
    maidenName: '',
    academicTitle: 'DR',
    proprietaryStatus: 'RENTING_OR_SUBTENANT',
    employmentStatus: 'EMPLOYED',
    houseNumberR: '',
    streetR: '',
    zipCodeR: '',
    cityR: '',
    countryR: 'germany',
    houseNumberC: '',
    streetC: '',
    zipCodeC: '',
    cityC: '',
    countryC: 'germany',
    employmentLevel: 'SENIOR_EMPLOYEE',
    selfEmployedTaxID: ''
  });

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const {
      firstName,
      lastName,
      sex,
      placeOfBirth,
      emailAddress,
      internationalAreaCode,
      areaCode,
      telephoneNumber,
      nationality,
      maritalStatus,
      // maidenName,
      // academicTitle,
      proprietaryStatus,
      employmentStatus,
      houseNumberR,
      streetR,
      zipCodeR,
      cityR,
      countryR,
      houseNumberC,
      streetC,
      zipCodeC,
      cityC,
      countryC,
      employmentLevel,
      selfEmployedTaxID
    } = formInput;


    let client_token = localStorage.getItem('client_token');
    let data = {
      currentAccount: {
        accountProductId: 'DB_BESTKONTO',
        accountCurrency: 'EUR'
      },
      digitalServices: [
        {
          partnerIndex: 1
        }
      ],
      isActingForOwnAccount: true,
      overdraftCredit: {
        requestedOverdraftCredit: 0
      },
      partners: [
        {
          index: 1,
          firstName: firstName,
          lastName: lastName,
          sex: sex,
          dateOfBirth: format(dateOfBirth, 'yyyy-MM-dd'),
          placeOfBirth: placeOfBirth,
          schufaAccordance: false,
          nationality: nationality,
          maritalStatus: maritalStatus,
          proprietaryStatus: proprietaryStatus,
          employmentStatus: employmentStatus,
          registeredAddress: {
            city: cityR,
            country: countryR,
            houseNumber: houseNumberR,
            street: streetR,
            zipCode: zipCodeR
          },
          communicationAddress: {
            city: cityC,
            country: countryC,
            houseNumber: houseNumberC,
            street: streetC,
            zipCode: zipCodeC
          },
          phoneNumber: {
            internationalAreaCode: internationalAreaCode,
            areaCode: areaCode,
            telephoneNumber: telephoneNumber
          },
          emailAddress: emailAddress,
          employmentInformation: {
            employmentLevel: employmentLevel
          },
          taxInformation: {
            isBornInTheUS: false,
            isNonGermanTaxPayer: false,
            isGermanTaxPayer: true,
            isSecondNationalityUS: false,
            hasUSGreencard: false,
            selfEmployedTaxID: selfEmployedTaxID
          }
        }
      ]
    };


    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://simulator-api.db.com:443/gw/dbapi/banking/cashAccountOpenings/v1',
      headers: {
        'idempotency-id': uuidv4(),
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + client_token
      },
      data: data
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data);
        setServerResponse(response.data);
        handleOpen();
      })
      .catch((error) => {
        console.error(error);
        console.error(error.message);
      });
  };

  useEffect(() => {
    axios
      .get('https://www.induseuro.de/api/auth/gentoken')
      .then((response) => {
        // console.log(response.data.token);
        localStorage.setItem('temporaryToken', response.data.token);
        validateDeutscheToken(response.data.token);
        // navigate(`/transactions?access_token=${response.data.access_token}`)
        // navigate(`/transactions`);
      })
      .catch((err) => console.error(err));
  }, []);

  function validateDeutscheToken(token) {
    axios
      .post('https://www.induseuro.de/api/auth/genbearer', {
        token: token
      })
      .then((response) => {
        // console.log('response', response.data.access_token);
        localStorage.setItem('client_token', response.data.access_token);
      })
      .catch((err) => console.error(err));
  }

  const setValue = (event) => {
    setFormInput({
      ...formInput,
      [event.target.name]: event.target.value
    });
    setFormInputError({
      ...formInputError,
      [event.target.name]:
        event.target.value == undefined || event.target.value == ''
          ? false
          : true
    });
  };

  return (
    <div className="relative">
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 700,
            bgcolor: 'background.paper',
            borderRadius: 5,
            boxShadow: 24,
            p: 4
          }}
        >
          <div className="flex items-center ">
            <h2 className="text-3xl font-medium text-green-600">
              Cash account opening successful
            </h2>
          </div>
          <h2 className="mt-4 mb-4 text-xl font-medium">
            Application ID - {serverResponse.productApplicationId}
          </h2>
          <h2 className="mt-2 mb-4 text-xl font-medium">
            Application STATUS - {serverResponse.productApplicationStatus}
          </h2>
          <h2 className="mt-2 mb-4 text-xl font-medium">
            Pre Contratual Information -{' '}
            <a
              className="text-indigo-400"
              href={`${serverResponse.precontractualInformation}`}
            >
              External Link
            </a>
          </h2>
          <div className="mt-2 mb-4 text-sm">
            Save this information for your reference
          </div>
          {/* <div className="flex">
          <button
            type="button"
            className="text-white bg-green-800 hover:bg-green-900 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          >
            <svg
              aria-hidden="true"
              className="-ml-0.5 mr-2 h-4 w-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
              <path
                fill-rule="evenodd"
                d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            View more
          </button>
          <button
            type="button"
            className="text-green-800 bg-transparent border border-green-800 hover:bg-green-900 hover:text-white focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-green-600 dark:border-green-600 dark:text-green-400 dark:hover:text-white dark:focus:ring-green-800"
            data-dismiss-target="#alert-additional-content-3"
            aria-label="Close"
          >
            Dismiss
          </button>
        </div> */}
        </Box>
      </Modal>
      <div
        className="absolute border rounded-full border-gray-400 bg-white p-1 text-sm"
        style={{ left: 70 }}
      >
        <IconButton
          color="primary"
          aria-label="add to shopping cart"
          onClick={() => navigate(-1)}
        >
          <ArrowBackRounded />
        </IconButton>
      </div>

      <div className="isolate border border-gray-400 rounded-lg p-4 shadow-lg bg-white max-w-6xl mx-auto lg:px-8 mb-10 ">
        <div className="">
          <h2 className="text-3xl font-bold tracking-tight text-center m-4 text-gray-900 sm:text-4xl">
            Account Opening Form
          </h2>
        </div>
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          className="mx-auto mt-10 max-w-5xl sm:mt-10 grid gap-4 grid-cols-4"
          onChange={setValue}
        >
          <FormControl className="lg:col-span-2 col-span-4">
            <TextField
              label="First Name"
              type="text"
              sx={{ mb: 3 }}
              name="firstName"
              value={formInput.firstName}
              // error={formInputError.firstName}
              // helperText="This is a required field"
              required
            />
          </FormControl>
          <FormControl className="lg:col-span-2 col-span-4">
            <TextField
              label="Last Name"
              type="text"
              sx={{ mb: 3 }}
              name="lastName"
              onChange={setValue}
              value={formInput.lastName}
              // error={formInputError.lastName}
              // helperText="This is a required field"
              required
            />
          </FormControl>
          <div className="border border-gray-300 rounded mb-6 p-1 lg:col-span-2 col-span-4">
            <FormControl
              style={{ flexDirection: 'row', paddingLeft: 6, paddingTop: 4 }}
            >
              <FormLabel style={{ paddingRight: 10, paddingTop: 9 }}>
                Gender
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                onChange={setValue}
                name="sex"
                value={formInput.sex}
              >
                <FormControlLabel
                  value="FEMALE"
                  control={<Radio />}
                  label="Female"
                />
                <FormControlLabel
                  value="MALE"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  value="OTHER"
                  control={<Radio />}
                  label="Other"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FormControl fullWidth className="lg:col-span-2 col-span-4">
              <DatePicker
                sx={{ mb: 3 }}
                label="Date of Birth"
                value={dateOfBirth}
                // name="dateOfBirth"
                onChange={setDateOfBirth}
              />
            </FormControl>
          </LocalizationProvider>
          <FormControl fullWidth className="lg:col-span-2 col-span-4">
            <TextField
              label="Place of Birth"
              type="text"
              sx={{ mb: 3 }}
              onChange={setValue}
              name="placeOfBirth"
              value={formInput.placeOfBirth}
              required
            />
          </FormControl>
          <FormControl fullWidth className="lg:col-span-2 col-span-4">
            <TextField
              label="Email"
              onChange={setValue}
              type="email"
              sx={{ mb: 3 }}
              name="emailAddress"
              value={formInput.emailAddress}
              required
            />
          </FormControl>
          <FormControl fullWidth className="lg:col-span-1 col-span-4">
            <TextField
              label="International Area Code"
              type="text"
              sx={{ mb: 3 }}
              name="internationalAreaCode"
              value={formInput.internationalAreaCode}
              onChange={setValue}
              required
            />
          </FormControl>
          <FormControl fullWidth className="lg:col-span-1 col-span-4">
            <TextField
              label="Area Code"
              type="number"
              sx={{ mb: 3 }}
              name="areaCode"
              value={formInput.areaCode}
              onChange={setValue}
              required
            />
          </FormControl>
          <FormControl fullWidth className="lg:col-span-2 col-span-4">
            <TextField
              label="Phone Number"
              type="number"
              sx={{ mb: 3 }}
              name="telephoneNumber"
              onChange={setValue}
              value={formInput.telephoneNumber}
              required
            />
          </FormControl>
          <FormControl fullWidth className="lg:col-span-2 col-span-4">
            <InputLabel id="demo-simple-select-label">Nationality</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formInput.nationality}
              label="Nationality"
              sx={{ mb: 3 }}
              name="nationality"
              onChange={setValue}
              required
            >
              <MenuItem value={'german'}>German</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth className="lg:col-span-2 col-span-4">
            <InputLabel id="demo-simple-select-label">
              Marital Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formInput.maritalStatus}
              label="Marital Status"
              sx={{ mb: 3 }}
              name="maritalStatus"
              onChange={setValue}
              required
            >
              <MenuItem value={'UNKNOWN'}>Unknown</MenuItem>
              <MenuItem value={'SINGLE'}>Single</MenuItem>
              <MenuItem value={'MARRIED_SEPARATE_TAX_ASSESSMENT'}>
                Married Separate Tax Assessment
              </MenuItem>
              <MenuItem value={'DIVORCED'}>Divorced</MenuItem>
              <MenuItem value={'SINGLE_LIVING_IN_COHABITATION'}>
                Single living in cohabitation
              </MenuItem>
              <MenuItem value={'MARRIED_SEPARATED'}>Married Separated</MenuItem>
              <MenuItem value={'MARRIED_JOINT_TAX_ASSESSMENT'}>
                Married Joint Tax Assessment
              </MenuItem>
              <MenuItem value={'CIVIL_UNION_JOINT_TAX_ASSESSMENT'}>
                Civil Union Joint Tax Assessment
              </MenuItem>
              <MenuItem value={'CIVIL_UNION_SEPARATE_TAX_ASSESSMENT'}>
                Civil Union Separate Tax Assessment
              </MenuItem>
              <MenuItem value={'CIVIL_UNION_SEPARATED'}>
                Civil Union Separated
              </MenuItem>
            </Select>
          </FormControl>

          {/* <FormControl fullWidth className="lg:col-span-1 col-span-4">
            <TextField
              label="Maiden Name"
              type="text"
              sx={{ mb: 3 }}
              className="sm:col-span-3"
              value={formInput.maidenName}
              name="maidenName"
              onChange={setValue}
              
            />
          </FormControl> */}
          {/* <FormControl fullWidth className="lg:col-span-1 col-span-4">
            <InputLabel id="demo-simple-select-label">
              Academic Title
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formInput.academicTitle}
              label="Academic Title"
              sx={{ mb: 3 }}
              name="academicTitle"
              onChange={setValue}
              required
            >
              <MenuItem value={'DR'}>Dr.</MenuItem>
              <MenuItem value={'PROF'}>Prof.</MenuItem>
              <MenuItem value={'PROF_DR'}>Prof. Dr.</MenuItem>
            </Select>
          </FormControl> */}
          <FormControl fullWidth className="lg:col-span-2 col-span-4">
            <InputLabel id="demo-simple-select-label">
              Proprietary Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formInput.proprietaryStatus}
              label="Proprietary Status"
              sx={{ mb: 3 }}
              name="proprietaryStatus"
              onChange={setValue}
              required
            >
              <MenuItem value={'RENTING_OR_SUBTENANT'}>
                Renting or Subtenant
              </MenuItem>
              <MenuItem value={'OWN_PROPERTY'}>Own Property</MenuItem>
              <MenuItem value={'WITH_PARENTS'}>With Parents</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth className="lg:col-span-2 col-span-4">
            <InputLabel id="demo-simple-select-label">
              Employment Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formInput.employmentStatus}
              label="Employment Status"
              sx={{ mb: 3 }}
              name="employmentStatus"
              onChange={setValue}
              required
            >
              <MenuItem value={'EMPLOYED'}>Employed</MenuItem>
              <MenuItem value={'SELF_EMPLOYED'}>Self Employed</MenuItem>
            </Select>
          </FormControl>
          <div className="text-center col-span-4">
            <h2 className="text-2xl font-bold tracking-tight text-center m-4 text-gray-900 ">
              Registered Address
            </h2>
          </div>

          <FormControl fullWidth className="lg:col-span-1 col-span-4">
            <TextField
              label="House Number"
              type="text"
              sx={{ mb: 3 }}
              name="houseNumberR"
              onChange={setValue}
              value={formInput.houseNumberR}
              required
            />
          </FormControl>
          <FormControl fullWidth className="lg:col-span-3 col-span-4">
            <TextField
              label="Street"
              type="text"
              sx={{ mb: 3 }}
              value={formInput.streetR}
              name="streetR"
              onChange={setValue}
              required
            />
          </FormControl>
          <FormControl fullWidth className="lg:col-span-1 col-span-4">
            <TextField
              label="Zip code"
              type="number"
              sx={{ mb: 3 }}
              value={formInput.zipCodeR}
              name="zipCodeR"
              onChange={setValue}
              required
            />
          </FormControl>
          <FormControl fullWidth className="lg:col-span-1 col-span-4">
            <TextField
              label="City"
              type="text"
              sx={{ mb: 3 }}
              value={formInput.cityR}
              name="cityR"
              onChange={setValue}
              required
            />
          </FormControl>
          <FormControl fullWidth className="lg:col-span-2 col-span-4">
            <InputLabel id="demo-simple-select-label">Country</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formInput.countryR}
              label="Country"
              sx={{ mb: 3 }}
              name="countryR"
              onChange={setValue}
              required
            >
              <MenuItem value={'germany'}>Germany</MenuItem>
            </Select>
          </FormControl>
          <div className="col-span-4">
            <h2 className="text-2xl font-bold tracking-tight text-center m-4 text-gray-900">
              Communication Address
            </h2>
          </div>

          <FormControl fullWidth className="lg:col-span-1 col-span-4">
            <TextField
              label="House Number"
              type="text"
              sx={{ mb: 3 }}
              value={formInput.houseNumberC}
              name="houseNumberC"
              onChange={setValue}
              required
            />
          </FormControl>
          <FormControl fullWidth className="lg:col-span-3 col-span-4">
            <TextField
              label="Street"
              type="text"
              sx={{ mb: 3 }}
              value={formInput.streetC}
              name="streetC"
              onChange={setValue}
              required
            />
          </FormControl>
          <FormControl fullWidth className="lg:col-span-1 col-span-4">
            <TextField
              label="Zip code"
              type="number"
              sx={{ mb: 3 }}
              value={formInput.zipCodeC}
              name="zipCodeC"
              onChange={setValue}
              required
            />
          </FormControl>
          <FormControl fullWidth className="lg:col-span-1 col-span-4">
            <TextField
              label="City"
              type="text"
              sx={{ mb: 3 }}
              value={formInput.cityC}
              name="cityC"
              onChange={setValue}
              required
            />
          </FormControl>
          <FormControl fullWidth className="lg:col-span-2 col-span-4">
            <InputLabel id="demo-simple-select-label">Country</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formInput.countryC}
              label="Country"
              sx={{ mb: 3 }}
              name="countryC"
              onChange={setValue}
              required
            >
              <MenuItem value={'germany'}>Germany</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth className="lg:col-span-2 col-span-4">
            <InputLabel id="demo-simple-select-label">
              Employment level
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formInput.employmentLevel}
              label="Employment level"
              sx={{ mb: 3 }}
              name="employmentLevel"
              onChange={setValue}
              required
            >
              <MenuItem value={'SENIOR_EMPLOYEE'}>Senior Employee</MenuItem>
              <MenuItem value={'QUALIFIED_EMPLOYEE'}>
                Qualified Employee
              </MenuItem>
              <MenuItem value={'SALARIED_EMPLOYEE'}>Salaried Employee</MenuItem>
              <MenuItem value={'SENIOR_CIVIL_SERVANT'}>
                Senior Civil Servant
              </MenuItem>
              <MenuItem value={'MIDDLE_CIVIL_SERVANT'}>
                Middle Civil Servant
              </MenuItem>
              <MenuItem value={'JUNIOR_CIVIL_SERVANT'}>
                Junior Civil Servant
              </MenuItem>
              <MenuItem value={'MASTER'}>Master</MenuItem>
              <MenuItem value={'SKILLED_WORKER'}>Skilled Worker</MenuItem>
              <MenuItem value={'WORKER'}>Worker</MenuItem>
              <MenuItem value={'RETIRED'}>Retired</MenuItem>
              <MenuItem value={'STUDENT'}>Student</MenuItem>
              <MenuItem value={'SCHOLAR'}>Scholar</MenuItem>
              <MenuItem value={'MILITARY_COMMUNITY_SERVICE'}>
                Military Community Service
              </MenuItem>
              <MenuItem value={'APPRENTICE'}>Apprentice</MenuItem>
              <MenuItem value={'HOMEWORKER'}>Home Worker</MenuItem>
              <MenuItem value={'UNEMPLOYED'}>Unemployed</MenuItem>
              <MenuItem value={'CHAIRMAN'}>Chairman</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth className="lg:col-span-2 col-span-4">
            <TextField
              label="Self Employeed Tax ID"
              type="number"
              sx={{ mb: 3 }}
              value={formInput.selfEmployedTaxID}
              name="selfEmployedTaxID"
              onChange={setValue}
              required
            />
          </FormControl>
          <div className="text-center col-span-4">
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              type="submit"
            >
              Submit
            </Button>
          </div>

          {/* <small className="">
          Need an account? <Link to="/">Register here</Link>
        </small> */}
        </form>
      </div>
    </div>
  );
}
