import React from 'react';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export default function BasicCard(props) {
  return (
    <div
      className={`rounded-lg bg-white rounded-md p-2 mb-10 font-semibold shadow ${
        props.data.active
          ? 'bg-gray-50 text-indigo-600 border-2 border-indigo-600'
          : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50 shadow-lg'
      }`}
      style={{
        width: '20%',
        height: 170,
        alignContent: 'center',
        alignItems: 'center',
        alignItems: 'center',
        marginRight: 2,
        maxWidth: 220,
        minWidth: 180
      }}
    >
      <a href="/">
        <CardContent
          style={{
            alignSelf: 'center',
            margin: 'auto',
            flexDirection: 'column',
            alignItems: 'centter',
            alignContent: 'center',
            justifyContent: 'center'
          }}
        >
          <div
            style={{
              width: 'inherit',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <img
              src={require(`../../assets/${props.data.img}`)}
              width={90}
              height={90}
            />
          </div>
          <div
            style={{ marginBottom: 4, textAlign: 'center' }}
            className={`font-semibold`}
            variant="body2"
          >
            {props.data.bankname}
          </div>
          <Typography
            sx={{ fontSize: 15, textAlign: 'center' }}
            color="text.secondary"
          >
            {props.data.type}
          </Typography>
        </CardContent>
      </a>
    </div>
  );
}
