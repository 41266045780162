import * as React from 'react';
import Cards from './Layout/Cards';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from '../Pages/Home';
import Transactions from '../Pages/Transaction';
import Register from '../Pages/Register';
import ProcessingPage from '../Pages/ProcessingPage';

export default function HeroSection() {
  const BanksList = [
    {
      id: '1a',
      bankname: 'Deustche Bank',
      type: 'Private Bank',
      img: 'deutschebank.png',
      availablility: true,
      active: true
    },
    {
      id: '2b',
      bankname: 'Deustche Bank',
      type: 'Corporate Bank(SME)',
      img: 'deutschebank.png',
      availablility: false,
      active: false
    },
    {
      id: '3c',
      bankname: 'Deustche Bank',
      type: 'Enterprises',
      img: 'deutschebank.png',
      availablility: false,
      active: false
    },
    {
      id: '4d',
      bankname: 'Noris Bank',
      type: '',
      img: 'norisbank.png',
      availablility: false,
      active: false
    },
    {
      id: '5c',
      bankname: 'PostBank',
      type: '',
      img: 'postbank.png',
      availablility: false,
      active: false
    }
  ];
  return (
    <div className="bg-slate-50">
      <div className="relative isolate pt-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)'
            }}
          />
        </div>
        <div className="mx-auto px-6 lg:px-8">
          <div className="mx-auto text-center">
            <div
              style={{
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'space-around',
                alignSelf: 'center',
                margin: 'auto',
                flexWrap: 'wrap'
              }}
            >
              {BanksList.map((item, index) => {
                return <Cards key={item.id} data={item} />;
              })}
            </div>
          </div>
        </div>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/processing" element={<ProcessingPage />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/register" element={<Register />} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}
