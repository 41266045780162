import React, { useEffect, useState } from 'react';
import SideBar from '../Components/Layout/SideBar';
import { RadioGroup } from '@headlessui/react';
import axios from 'axios';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
// import './shadow.css';
import Loader from '../Components/Loader';

const notificationMethods = [
  { id: 'email', title: 'Period of time' },
  { id: 'sms', title: 'From' }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Transactions() {

  const [transactions, setTransactions] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [offset, setOffset] = useState(0);
  const [pages, setPages] = useState(0);


  function getTransactions(iban) {
    let access_token = localStorage.getItem('access_token');
    let limit = 100;

    axios
      .get(
        `https://simulator-api.db.com:443/gw/dbapi/banking/transactions/v2?iban=${iban}&currencyCode=EUR&limit=${limit}&offset=${offset}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      )
      .then((response) => {
        setTransactions(response?.data?.transactions);
        setTotalItems(response?.data?.totalItems);
        setPages(Math.ceil(response?.data?.totalItems / 100));
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        console.error(error.message);
      });
  }

  useEffect(() => {
    let access_token = localStorage.getItem('access_token');

    axios
      .get(
        'https://simulator-api.db.com:443/gw/dbapi/banking/cashAccounts/v2',
        {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        }
      )
      .then((response) => {
        setAccounts(response?.data?.accounts);
        setSelected(response?.data?.accounts[0]?.iban);
        getTransactions(response?.data?.accounts[0]?.iban);
      })
      .catch((error) => {
        console.error(error);
        console.error(error.response.data.code == 1);
        if (error.response.data.code == 1) {
          setShowToast(true);
        }
      });
  }, []);

  useEffect(() => {
      getTransactions(selected)
  },[offset])

  return (
    <div className="flex bg-transparent">
      <SideBar />
      <div
        id="toast-top-right"
        className={`fixed flex items-center p-4 space-x-4 text-gray-500 bg-white rounded-lg shadow top-5 right-5 dark:text-gray-400 space-x dark:bg-gray-800 ${
          showToast ? '' : 'hidden'
        }`}
        role="alert"
      >
        <div className="inline-flex items-center justify-center flex-shrink-0 w-12 h-12 text-orange-500 bg-orange-100 rounded-lg dark:bg-orange-700 dark:text-orange-200">
          <svg
            aria-hidden="true"
            className="w-8 h-8"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span className="sr-only">Warning icon</span>
        </div>
        <div className="ml-3 text-xl font-normal">Session Expired</div>
        <a
          className="text-xl font-medium text-blue-600 p-1.5 hover:bg-blue-100 rounded-lg dark:text-blue-500 dark:hover:bg-gray-700"
          href="/"
        >
          Login
        </a>
        <button
          type="button"
          className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
          data-dismiss-target="#toast-warning"
          aria-label="Close"
        >
          <span className="sr-only">Close</span>
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
      <div className="flex-grow px-4 mx-3 ">
        <div className="overflow-hidden rounded-lg bg-white shadow-md">
          <div className="px-4 py-5 sm:p-6">
            <div className="flex flex-row justify-around">
              <div className="text-semibold w-1/6 text-center">IBAN</div>
              <div className="text-semibold w-5/6 text-center">
                Account Type
              </div>
              <div className="text-semibold w-1/6 text-center">Balance</div>
            </div>
            <RadioGroup
              value={selected}
              onChange={(e) => {
                setSelected(e);
                getTransactions(e);
                setLoading(true);
                setOffset(0)
              }}
            >
              <div className="relative -space-y-px rounded-md bg-white">
                {accounts.map((plan, planIdx) => (
                  <RadioGroup.Option
                    key={planIdx}
                    value={plan?.iban}
                    className={({ checked }) =>
                      classNames(
                        planIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                        planIdx === accounts.length - 1
                          ? 'rounded-bl-md rounded-br-md'
                          : '',
                        checked
                          ? 'z-10 border-indigo-200 bg-indigo-50'
                          : 'border-gray-200',
                        'relative flex cursor-pointer flex-col border p-4 focus:outline-none md:grid md:grid-cols-3 md:pl-4 md:pr-6'
                      )
                    }
                  >
                    {({ active, checked }) => (
                      <>
                        <span className="flex items-center text-sm">
                          <span
                            className={classNames(
                              checked
                                ? 'bg-indigo-600 border-transparent'
                                : 'bg-white border-gray-300',
                              active
                                ? 'ring-2 ring-offset-2 ring-indigo-600'
                                : '',
                              'h-4 w-4 rounded-full border flex items-center justify-center'
                            )}
                            aria-hidden="true"
                          >
                            <span className="rounded-full bg-white w-1.5 h-1.5" />
                          </span>
                          <RadioGroup.Label
                            as="span"
                            className={classNames(
                              checked ? 'text-indigo-900' : 'text-gray-900',
                              'ml-3 font-medium'
                            )}
                          >
                            {plan.iban}
                          </RadioGroup.Label>
                        </span>
                        <RadioGroup.Description
                          as="span"
                          className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center"
                        >
                          <span
                            className={classNames(
                              checked ? 'text-indigo-900' : 'text-gray-900',
                              'font-medium'
                            )}
                          >
                            {plan.accountType}
                          </span>{' '}
                          {/* <span className={checked ? 'text-indigo-700' : 'text-gray-500'}>(${plan.priceYearly} / yr)</span> */}
                        </RadioGroup.Description>
                        <RadioGroup.Description
                          as="span"
                          className={classNames(
                            checked ? 'text-indigo-700' : 'text-gray-500',
                            'ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right'
                          )}
                        >
                          {plan.currentBalance} {plan.currencyCode}
                        </RadioGroup.Description>
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
        </div>

        <div className="overflow-hidden rounded-lg bg-white shadow-md my-4 box-shadow">
          <div className="px-4 py-5 sm:p-6">
            {/* <Loader /> */}
            <div className="flow-root">
              <h2 className="text-2xl font-bold tracking-tight text-center m-1 mb-3 text-gray-900 ">
                Transactions
              </h2>
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Value Date
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Reference
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {transactions?.map((transaction, index) => (
                          <tr key={index}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {transaction.valueDate}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {transaction.counterPartyName}
                            </td>
                            <td
                              className={`whitespace-nowrap px-3 py-4 text-lg ${
                                transaction.amount > 0
                                  ? 'text-green-500'
                                  : 'text-red-500'
                              }`}
                            >
                              {transaction.amount > 0 ? '+' : ''}
                              {transaction.amount} EUR
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                      <div className="flex flex-1 justify-between sm:hidden">
                        <a
                          href="#"
                          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                        >
                          Previous
                        </a>
                        <a
                          href="#"
                          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                        >
                          Next
                        </a>
                      </div>
                      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                        <div>
                          <p className="text-sm text-gray-700">
                            {/* Showing <span className="font-medium">{totalItems - offset*100 > 0 ? offset*100 : 1 }</span> to{' '} */}
                            Showing <span className="font-medium">{offset == 0 ? 1 : offset*100 }</span> to{' '}
                            <span className="font-medium">
                              {/* {totalItems < 100 ? totalItems : offset*100} */}
                              {totalItems < 100 ? totalItems : offset == 0 && totalItems > 100 ? 100 : offset > 0 ? offset*100 +  totalItems - offset*100 :'0'}
                            </span>{' '}
                            of <span className="font-medium">{totalItems}</span>{' '}
                            results
                          </p>
                        </div>
                        <div>
                          {pages <= 1 ? (
                            <a
                              onClick={() => setOffset(0)}
                              aria-current="page"
                              className="relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              1
                            </a>
                          ) : (
                            <nav
                              className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                              aria-label="Pagination"
                            >
                              <a
                                onClick={() => {
                                  if(offset > 0){
                                    setOffset(offset - 1)
                                  }
                                }}
                                className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                              >
                                <span className="sr-only">Previous</span>
                                <ChevronLeftIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </a>
                              <a
                                onClick={() => {
                                  if(offset < Math.floor(totalItems / 100)){
                                    setOffset(offset + 1)
                                  }
                                }}
                                className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                              >
                                <span className="sr-only">Next</span>
                                <ChevronRightIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </a>
                            </nav>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
