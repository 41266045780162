import React from 'react';
import HeroSection from '../HeroSection';
import Footer from './Footer';
import Header from './Header';

export default function Layout() {
  return (
    <>
      <Header />
      <HeroSection />
      <Footer />
    </>
  );
}
